export const FETCH_CATEGORIES_BY_SECTION = 'categories_by_section';
export const FETCH_ANUNCIOS_LIST = 'fetch_anuncios_list';
export const FETCH_ANUNCIO_BY = 'fetch_anuncio_by';
export const FETCH_CATEGORIES_LIST = 'fetch_categories_list';
export const FETCH_STATE_LIST = 'fetch_state_list';
export const FETCH_CITY_LIST = 'fetch_city_list';
export const CLICK_UPDATE_ANUNCIO = 'CLICK_UPDATE_ANUNCIO';
export const CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION';
export const FETCH_CURRENT_USER = 'fetch_current_user';
export const STORE_USER_FILES = 'store_user_files';
export const DELIVER_DEFAULT_USER = 'deliver_default_user';
export const FETCH_MOTIVOS_LIST = "fetch_motivos_list";
export const FETCH_RELACIONADOS = "fetch_relacionados";