import React, { Component } from 'react';
import {
    Reportar,
    ReportButton,
    ReportForm,
    ReportFormTitle,
    ReportFormContainer,
    ReportFormBtn,
    ReportOption,
    ReportText,
    ReportLabel,
    ReportFeedback,
    SuccessIcon,
    ErrorIcon
} from './reportar-styled';
import Axios from 'axios';
import { sendAnalytics } from '../analytics-gjc/analytics-gjc';
import { analyticsEvent } from '../analytics-gjc/analytics-gjc-mockup';

// TODO: Adicionar eventos
export default class ReportarAnuncio extends Component {

    constructor(props) {
        
        super(props);

        this.state = {
            listaMotivos: [],
            showReport: false,
            showOthers: false,
            outroMotivo: '',
            mensagemEnviada: false,
            erroMensagem: false,
            enviando: false
        }

        this.hideFeedback = this.hideFeedback.bind(this);
        this.handleReport = this.handleReport.bind(this);
        this.handleOtherText = this.handleOtherText.bind(this);
        this.handleOthers = this.handleOthers.bind(this);
        this.selectMotivo = this.selectMotivo.bind(this);
        this.sendReport = this.sendReport.bind(this);
    }

    handleReport() {
        this.setState(prevState => ({
            showReport: !prevState.showReport
        }));
    }

    handleOtherText(e) {
        e.preventDefault();

        this.setState({ outroMotivo: e.target.value });
    }

    handleOthers() {
        this.setState(prevState => ({
            showOthers: !prevState.showOthers
        }));
    }

    hideFeedback() {
        this.setState({ mensagemEnviada: false, erroMensagem: false });
    }

    selectMotivo(index) {
        let lista = this.props.listaMotivos;
        lista[index].checked = !lista[index].checked;

        this.setState({ listaMotivos: lista });
    }

    sendReport() {
        const { listaMotivos, outroMotivo } = this.state;

        this.setState({ enviando: true });

        let listaFiltrada = listaMotivos.filter(i => i.checked).map(txt => txt.descricao)

        const obj = {
            "idAnuncio": this.props.idAnuncio,
            "urlAnuncio": window.location.href,
            "mensagem": outroMotivo ? outroMotivo : null,
            "listaMotivo": listaFiltrada
        };

        Axios.post(`${process.env.API_URL}/vitrine-negocios/sendDenuncia`, obj)
            .then((response) => {
                this.setState({ mensagemEnviada: true, enviando: false });
                sendAnalytics(analyticsEvent.REPORTAR_ERRO, this.props.analyticsJson);
            }).catch(error => {
                this.setState({ erroMensagem: true, enviando: false });
            });
    }

    render() {

        const { listaMotivos } = this.props;

        return (
            <Reportar>

                <ReportButton onClick={this.handleReport}>Reportar erro / Denunciar oferta</ReportButton>

                <ReportForm showReport={this.state.showReport}>

                    <ReportFormTitle>
                        Denunciar anúncio
                    </ReportFormTitle>

                    <ReportFormContainer>
                        {listaMotivos && listaMotivos.map((i, index) => {
                            return <ReportOption htmlFor={'input-' + index} key={'key-' + index} onClick={() => this.selectMotivo(index)}>
                                <input className="checkmark" name={'input-' + index} type="checkbox" />
                                <span>{i.descricao}</span>
                            </ReportOption>
                        })
                        }

                        <ReportOption htmlFor="input-99">
                            <input className="checkmark" name="input-1" type="checkbox" onChange={this.handleOthers} />
                            <span>Outros.</span>
                        </ReportOption>

                        <ReportLabel showOthers={this.state.showOthers}>Descrição da denúncia</ReportLabel>
                        <ReportText showOthers={this.state.showOthers} value={this.state.outroMotivo} onChange={this.handleOtherText} />

                        <ReportFormBtn onClick={this.sendReport}>Enviar</ReportFormBtn>

                    </ReportFormContainer>

                </ReportForm>

                <ReportFeedback showMsg={this.state.enviando}>
                    <ReportLabel showOthers={true}>Enviando...</ReportLabel>
                </ReportFeedback>

                <ReportFeedback showMsg={this.state.mensagemEnviada}>
                    <SuccessIcon />
                    <ReportLabel showOthers={true}>Obrigado, sua denúncia será analisada.</ReportLabel>
                </ReportFeedback>

                <ReportFeedback showMsg={this.state.erroMensagem}>
                    <ErrorIcon />
                    <ReportLabel showOthers={true}>Erro ao enviar a mensagem, tente novamente.</ReportLabel>
                    <ReportFormBtn onClick={this.hideFeedback}>Voltar</ReportFormBtn>
                </ReportFeedback>

            </Reportar>
        );

    }

};