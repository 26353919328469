import React, { Component } from 'react';
import { withRouter } from 'react-router';

// Redux
import { connect } from 'react-redux';

// Components
import { Container, ContainerTopo, CardContainer, TituloBusca, ContainerTitulo } from './../../components/commons/theme-styled';
import CardAnuncio from './../../components/card-anuncio/card-anuncio';
import Paginacao from './../../components/paginacao/paginacao';
import Ordenacao from './../../components/ordenacao/ordenacao';
import FiltroBusca from './../../components/filtro/Filtros';
import { Hidden } from '@material-ui/core';
import Topbar from './../../components/topbar/topbar';
import GPTComponent from './../../components/gpt/GPT';
import NoResults from '../../components/no-results/noResults';
import { sendAnalytics, enviarPageView } from './../../components/analytics-gjc/analytics-gjc';
import { analyticsEvent } from './../../components/analytics-gjc/analytics-gjc-mockup';
import { Helmet } from 'react-helmet';
import Loading from '../../components/loading/loading';

// Colors
import { Theme } from './../../components/commons/vitrine-colors';

// Images
import ClassiLogo from './../../resources/svg/logo-classi.svg';

// Actions
import { fetchAnunciosList, getUser } from './../../actions';

// Mockups
import { mockupFiltro } from './../../resources/mockups/filtro-mockup';
import { UrlsClassiUtils } from './../../resources/methods/UrlsClassiUtils';
import { StringUtils } from './../../resources/methods/StringUtils';
import { initAnalytics } from '../../../helpers/analytics';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

class Busca extends Component {

  constructor(props) {

    super(props);

    //Utiliza-se a URL para definir preset de filtro, porém é necessário correlacionar o label na URL com o ID correspondente
    let estado = UrlsClassiUtils.getEstado(props.location.pathname);
    let cidade = UrlsClassiUtils.getCidade(props.location.pathname);
    let categoria = UrlsClassiUtils.getCategoria(props.location.pathname);
    let query = UrlsClassiUtils.getQuery(props.location.search);

    //Condição especial para Link com state de categoria, e que não reflete ionformaçã na URL.
    if (props.location.state && props.location.state.categoria) {
      categoria = StringUtils.cleanString(props.location.state.categoria);
    }

    let hasPresetFiltro = Boolean(estado || cidade || categoria || query);

    this.state = {
      filtro: {
        categoria: "",
        categoriaPai: "",
        cidade: "",
        estado: estado ? estado.toUpperCase() : "",
        ordenacao: "RELEVANCIA",
        pagina: 0,
        qtdRegistros: 12,
        paginado: true,
        query: query ? query : "",
        secao: "NEGOCIOS",
        vlrMaximo: "",
        vlrMinimo: ""
      },
      presetFiltro: {
        estado: estado,
        cidade: cidade,
        categoria: categoria,
        query: query
      },
      hasPresetFiltro: hasPresetFiltro,
      showLoading: true
    };

  }

  componentDidMount() {

    const { anuncios, filtroSetup } = this.props.searchFilterData;
    const { filtro } = this.state;

    if (!anuncios || !filtroSetup) {
      this.props.fetchAnunciosList(filtro);
    } else if (
      filtroSetup.cidade !== filtro.cidade ||
      filtroSetup.estado !== filtro.estado ||
      filtroSetup.categoria !== filtro.categoria
    ) {
      this.props.fetchAnunciosList(filtro);
    } else {
      this.setState({ showLoading: false, hasPresetFiltro: false });
    }

    initAnalytics('vitrine_negocios_busca', process.env.SITEID_ANALYTICS);
    enviarPageView();

  }

  componentDidUpdate(prevProps) {

    const { searchFilterData, authentication } = this.props;

    //Atualiza lista de anúncios em caso de mudança de usuários.
    if (prevProps.authentication.userEmail != authentication.userEmail) {
      this.props.fetchAnunciosList(this.state.filtro);
    } else if (prevProps.searchFilterData != searchFilterData) {

      this.setState({
        categoria: searchFilterData.selectedCategoria,

        anuncios: searchFilterData.anuncios,
        cargos: searchFilterData.cargos,
        cidades: searchFilterData.cidades,
        estados: searchFilterData.estados,
        experiencias: searchFilterData.experiencias,
        formacoes: searchFilterData.formacoes,
        ordenacao: searchFilterData.ordenacao,
        tiposVaga: searchFilterData.tiposVaga,
        showLoading: false
      })

    } else if (this.state.hasPresetFiltro) {
      this.setState({ hasPresetFiltro: false });
    }

  }

  handleNewPlaybills = (key, value) => {

    const { filtro } = this.state;
    let newFilter = filtro;

    if (Array.isArray(key)) {
      key.map((item, index) => newFilter[item] = value[index]);
    }

    if (newFilter[key] !== value) {

      if (key === 'estado') { //Caso especial, no qual o campo cidade deve ser limpado
        newFilter['cidade'] = "";
      }

      if (key !== 'pagina') {
        newFilter['pagina'] = 0; //Toda alteração de filtro, menos de página, reseta a paginação
      }

      newFilter[key] = value;

      this.setNewUrl(newFilter);

      if (this.topContainer) {
        this.topContainer.scrollIntoView({ behavior: 'smooth' });
      }

      this.setState({ filtro: newFilter, showLoading: true, hasPresetFiltro: false });
      this.props.fetchAnunciosList(newFilter);

    }

    sendAnalytics(analyticsEvent.FILTRAR, filtro);

  }

  setNewUrl(filtro) {

    if (!this.state.hasPresetFiltro && this.props.searchFilterData && this.props.searchFilterData.categoriasPai) {

      let indexCategoria = this.props.searchFilterData.categoriasPai.findIndex(function (o) {
        return o.valor === filtro.categoriaPai
      });

      let categoria = null;

      if (indexCategoria >= 0) {
        categoria = this.props.searchFilterData.categoriasPai[indexCategoria];
      }

      let newPathname = UrlsClassiUtils.makeNewPathname(this.props.location.pathname, filtro.estado, filtro.cidade, (categoria ? categoria.descricao : null))
      let newSearch = UrlsClassiUtils.makeNewSearch(this.props.location.search, filtro.query, filtro.pagina, false);

      window.history.pushState(null, null, window.location.origin + '/' + newPathname + (newSearch ? '?' + newSearch : ''));

    }

  }

  geraTextoResultados() {

    let textoResultados = "";
    const { filtro } = this.state;

    if (filtro.categoriaPai &&
      this.props.searchFilterData &&
      this.props.searchFilterData.categoriasPai
    ) {
      let indexCategoria = this.props.searchFilterData.categoriasPai.findIndex(function (o) {
        return o.valor == filtro.categoriaPai;
      })

      if (indexCategoria >= 0) {
        let categoria = this.props.searchFilterData.categoriasPai[indexCategoria];
        textoResultados = "Resultados para " + categoria.descricao;

        if (filtro.cidade) {
          textoResultados += " em " + filtro.cidade;
        }
      }

    }

    return textoResultados;

  }

  render() {

    const { anuncios } = this.props.searchFilterData;
    const { searchFilterData, authentication } = this.props;
    const { showLoading, presetFiltro, hasPresetFiltro } = this.state;

    return (
      <React.Fragment>

        <Helmet>
          <title> Classi - Negócios </title>

          {/* Google  */}
          <meta property="title" content="Classificados de serviços e negócios - Classi"></meta>
          <meta property="type" content="website"></meta>
          <meta property="url" content={process.env.BASE_URL}></meta>
          <meta property="image" content={process.env.BASE_URL + ClassiLogo}></meta>
          <meta property="description" content="Os melhores negócios estão no Classificados mais tradicional de Goiás. Encontre eletrônicos, eletrodomésticos, móveis, serviços e muito mais."></meta>
          <meta property="site_name" content="Classi"></meta>

          {/* Open Graph data  */}
          <meta property="og:title" content="Classificados de serviços e negócios - Classi"></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content={process.env.BASE_URL}></meta>
          <meta property="og:image" content={process.env.BASE_URL + ClassiLogo}></meta>
          <meta property="og:description" content="Os melhores negócios estão no Classificados mais tradicional de Goiás. Encontre eletrônicos, eletrodomésticos, móveis, serviços e muito mais."></meta>
          <meta property="og:site_name" content="Classi"></meta>
          <meta property="fb:admins" content="177140826180797"></meta>

          {/* Twitter */}
          <meta name="twitter:card" content="summary"></meta>
          <meta name="twitter:site" content="@classi_br"></meta>
          <meta name="twitter:title" content="Classificados de serviços e negócios - Classi"></meta>
          <meta name="twitter:description" content="Os melhores negócios estão no Classificados mais tradicional de Goiás. Encontre eletrônicos, eletrodomésticos, móveis, serviços e muito mais."></meta>
          <meta name="twitter:creator" content="@classi_br"></meta>
          <meta name="twitter:image:src" content={process.env.BASE_URL + ClassiLogo}></meta>
        </Helmet>

        <Container direction="column" noPadding={true} ref={(el) => { this.topContainer = el }}>

          <Container direction="column" noPadding={true}>

            <Topbar
              busca
              withAnchor={false}
              backgroundColor={Theme[vitrineType].main}
              filters={FiltroBusca}
              searchFilterData={searchFilterData}
              handleFilter={this.handleNewPlaybills}
              presetFiltro={hasPresetFiltro ? presetFiltro : null}/>

          </Container>

          {showLoading && <Loading />}

          {/* Intervenção */}
          <GPTComponent gtpId="intervencao" gpt="intervencao" />

          <ContainerTopo noPadding={true} firstContainer={true} direction="row" align="initial" justify="center" >

            <Container noPadding={true} direction="row" align="initial" justify="center">

              <Container noPadding={true} direction="row" align="initial" justify="center">
                <GPTComponent gptId="topo" key="publicidade-busca-top" gpt="small_maxiboard" />
              </Container>

              <ContainerTitulo maxWidth="1220px" justify="flex-end">

                <ContainerTitulo maxWidth="900px" justify="space-between">

                  <TituloBusca>{this.geraTextoResultados()}</TituloBusca>
                  {anuncios && anuncios.length > 0 &&
                    <Ordenacao vitrinecolor={Theme[vitrineType].main} handleFilter={this.handleNewPlaybills} filterName='ordenacao' sortList={searchFilterData.ordenacao} />
                  }
                </ContainerTitulo>

              </ContainerTitulo>

            </Container>

            <Hidden xsDown>

              <FiltroBusca
                searchFilterData={searchFilterData}
                handleFilter={this.handleNewPlaybills}
                presetFiltro={hasPresetFiltro ? presetFiltro : null} />
                
            </Hidden>

            {anuncios && anuncios.length > 0 ?
              <CardContainer>
                {anuncios && anuncios.map((anuncio, index) => {
                  return <div key={index}>
                    {(index !== 0 && !(index % 3)) ? <GPTComponent gptId={`card-${index}`} key={`publicidade-${index}`} gpt="megabox" /> : null}

                    <CardAnuncio
                      key={anuncio.id}
                      detalhes={{
                        // Carro/Moto
                        anoFabricacao: anuncio.anoFabricacao || '',
                        anoVersao: anuncio.anoVersao || '',
                        quilometragem: anuncio.quilometragem || '',
                        tipoCombustivel: anuncio.tipoCombustivel || '',
                        //Imovel
                        areaUtil: anuncio.areaUtil || '',
                        qtdeQuartos: anuncio.qtdeQuartos || '',
                        qtdeVagas: anuncio.qtdeVagas || ''
                      }}
                      vitrine={anuncio.secao.value}
                      vitrineNome={anuncio.secao.descricao}
                      titulo={anuncio.titulo}
                      descricao={anuncio.descricao}
                      valor={anuncio.valor && anuncio.valor > 0 ? anuncio.valor : null}
                      localizacao={anuncio.localizacao.cidade + ' - ' + anuncio.localizacao.estado}
                      thumbnail={anuncio.imagens && anuncio.imagens.length > 0 ? anuncio.imagens[0].imageUrl : null}
                      url={anuncio.url}
                      isFavorite={anuncio.favorito}
                      idAnuncio={anuncio.id}
                      authentication={authentication}
                      prioridade={anuncio.prioridade}
                    />
                  </div>
                })}
              </CardContainer>
              :
              <CardContainer>
                <NoResults />
              </CardContainer>
            }
          </ContainerTopo>

          <Container direction="row" align="initial" justify="center">

            <Paginacao
              filterName='pagina'
              handleFilter={this.handleNewPlaybills}
              qtdTotalRegistros={searchFilterData.qtdTotalRegistros}
              vitrinecolor={Theme[vitrineType].main}
              paginaAtual={searchFilterData.paginaAtual}
              qntdPaginas={searchFilterData.qtdPaginas}/>

          </Container>

        </Container>
        
      </React.Fragment>

    );

  }

}

const mapStateToProps = store => ({
  searchFilterData: store.search.searchFilterData,
  authentication: store.authentication.authentication
});

const loadData = async (store, match) => {
  await store.dispatch(fetchAnunciosList(mockupFiltro));
};

export default {
  loadData,
  component: connect(mapStateToProps, { fetchAnunciosList, getUser })(withRouter(Busca))
};