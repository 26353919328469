import {
    FETCH_MOTIVOS_LIST,
    FETCH_RELACIONADOS
} from '../actions/types';

const INITIAL_STATE = {
    motivosList: [],
    relacionados: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MOTIVOS_LIST:
            return { ...state, motivosList: action.payload }
        case FETCH_RELACIONADOS:
            return { ...state, relacionados: action.payload }
        default:
            return state;
    }
}