import React from 'react';
import PropTypes from 'prop-types';

//Style
import { LinkBtn, LoginContainer, LogoutContainer, Username } from './login-styled.jsx';
import { TextButton } from '../commons/theme-styled';

// Icon
import Clear from '@material-ui/icons/Clear';

class Login extends React.Component{

    state = {
        user: this.props.authentication,
        loggedIn: false
    }

    setUser = user => {
        this.setState({ 
            user: user, 
            loggedIn: user.userName ? true : false 
        })
    };

    handleKeycloak = () => {
       window.keycloak.login();
    }

    handleLogout = () => {
        // this.props.userLogout()
        window.keycloak.logout();
    }
    
    render() {
        const{ btncolor, authentication, routerLocation } = this.props;

        let hasKeys  = typeof authentication === 'object' && authentication !== null ? Object.keys(authentication) : null;
        hasKeys = hasKeys ? hasKeys.length > 0 : false;

        let isAuth = authentication ? authentication.isAuthenticated : false;

        if(authentication && hasKeys && isAuth){
            return (
                <LogoutContainer className='login-btn'>
                    <Username btncolor={ btncolor } href={`${process.env.URL_ANUNCIE}#perfil`}>{authentication.userName.split(' ')[0]}</Username>
                    <LinkBtn onClick={ this.handleLogout } size='small' btncolor={ btncolor }>
                        <Clear />
                    </LinkBtn>
                </LogoutContainer>
            )
        } else{
            return (
                <div onClick={ this.handleKeycloak }>
                    <LoginContainer className="login-btn">
                            <TextButton btncolor={ btncolor }>
                                Entrar
                            </TextButton>
                    </LoginContainer>
                </div>
                // <a href={`/api/login?redirect=${window.location.origin}${routerLocation}`}>
                //     <LoginContainer className="login-btn">
                //             <TextButton btncolor={ btncolor }>
                //                 Entrar
                //             </TextButton>
                //     </LoginContainer>
                // </a>
            )
        }
    } 
}

Login.propTypes = {
    btncolor: PropTypes.string.isRequired
};

export default Login;