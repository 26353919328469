import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { Topbar, TopbarContainer, TopbarInfo, Titulo, Subtitulo, SubtituloMobile, Moeda, Valor, Heart, Codigo, CodigoMobile } from './topbar-interno-styled';
import Favoritar from './../../components/favoritar/favoritar';

// Icons
import { Hidden } from '@material-ui/core';

// Styles

export default class TopBarInterno extends Component {
    // constructor(props){
    //     super(props);

    //     this.state = { isFavorite: false };

    //     this.favoritarAnuncio = this.favoritarAnuncio.bind(this);
    //     this.desfavoritarAnuncio = this.desfavoritarAnuncio.bind(this);
    //     this.clickFavoritar = this.clickFavoritar.bind(this);
    // }

    // componentDidMount(){
    //     this.state.isFavorite = this.props.isFavorite
    // }

    // clickFavoritar(){
    //     if(this.state.isFavorite){
    //         this.desfavoritarAnuncio();
    //     } else{
    //         this.favoritarAnuncio();
    //     }
    // }

    // desfavoritarAnuncio(){
    //     // TODO: Remover email de teste
    //     const data = { idAnuncio: this.props.idAnuncio, userEmail: "rafhael.oliveira@gjccorp.com.br" }

    //     Axios.get('/api/desfavoritarAnuncio', { params: data })
    //         .then((response) => {
    //             this.setState({ isFavorite: true });
    //         })
    // }

    // favoritarAnuncio(){
    //     // TODO: Remover email de teste
    //     const data = { idAnuncio: this.props.idAnuncio, userEmail: "rafhael.oliveira@gjccorp.com.br" }

    //     Axios.get('/api/favoritarAnuncio', { params: data })
    //         .then((response) => {
    //             this.setState({ isFavorite: true });
    //         })
    // }

    render(){
        const{ 
            authentication, 
            idAnuncio, 
            isFavorite,
            titulo,
            subtitulo,
            valor,
            codigo
        } = this.props;

        return(
            <Topbar>
                <TopbarContainer>
                    <TopbarInfo>
                        {/* <Titulo>Soldador/Serralheiro</Titulo> */}
                        <Titulo>{ titulo }</Titulo>
                        
                        <Subtitulo>
                            { this.props.subtitulo && this.props.subtitulo.estado }
                            { this.props.subtitulo }
                        </Subtitulo>
                    </TopbarInfo>

                    <Hidden smDown>
                        <TopbarInfo alignRight>
                            <Favoritar 
                                isFavorite={ isFavorite } 
                                idAnuncio={ idAnuncio } 
                                authentication={ authentication } 
                                isAnuncioPage={ true }
                                isTopAnunciePage={ true }
                            />

                            <Subtitulo>
                                Favoritar
                            </Subtitulo>
                        </TopbarInfo>
                    </Hidden>


                    <TopbarInfo hideInfo={ !valor }>
                        <Valor>
                            <Moeda>
                                R$ 
                            </Moeda>
                            { valor  }
                        </Valor>

                        {codigo ? 
                            <Codigo>
                                Cód. { codigo }
                            </Codigo>
                            :
                            null
                        }
                        
                    </TopbarInfo>

                    <TopbarInfo hideInfo={ false }>
                        <SubtituloMobile>
                            { this.props.subtitulo && this.props.subtitulo.estado }
                            { this.props.subtitulo }
                        </SubtituloMobile>
                    </TopbarInfo>

                    <TopbarInfo hideInfo={ !codigo }>
                        <CodigoMobile>
                            Cód. { codigo }
                        </CodigoMobile>
                    </TopbarInfo>

                </TopbarContainer>
            </Topbar>
        )
    }
};