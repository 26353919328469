export const sendAnalytics = (event, data) => {
    const userEmail = '';
    let copyObj = {};

    Object.assign(copyObj, data);

    // Caso seja o filtro de uma busca, ele deve remover alguns dados
    if(event == 'filtrar'){
        setValorAnuncio(copyObj);
        cleanPaginacao(copyObj);
        cleanData(copyObj);
    }

    let jsonAnalytics = {
        categoria: 'acao', 
        tipo: event,
        dados: copyObj,
        email: userEmail ?  userEmail : ''
    }

    if(window.AnalyticsGJC){
        window.AnalyticsGJC.filaAnalyticsGJC.push(['enviarEvento', jsonAnalytics]);
    } 
}

export const enviarPageView = (data) => {

    let jsonPageView = {};

    if (data) {
        jsonPageView.dados = data;
    }

    window.AnalyticsGJC.filaAnalyticsGJC.push(['enviarPageView', jsonPageView]);

}

function cleanPaginacao(obj){
    delete obj.pagina;
    delete obj.paginado;
    delete obj.qtdRegistros;
}

function cleanData(obj){
    for(let propName in obj){
        if(typeof obj[propName] === 'object'){
            obj[propName] = cleanData(obj[propName]);
        } else if(obj[propName] === null || obj[propName] === undefined || obj[propName] == '' || obj[propName] == {}){
            delete obj[propName];
        }
    }
    return obj;
}

function setValorAnuncio(obj){
    const { vlrMinimo, vlrMaximo } = obj;

    if(vlrMinimo >= 0 && vlrMaximo >= 0){
        obj.valorAnuncio = {
            min: vlrMinimo,
            max: vlrMaximo
        };
    } else {
        delete obj.valorAnuncio;
    }

    delete obj.vlrMinimo;
    delete obj.vlrMaximo;

    return obj;
}