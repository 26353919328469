import React, { Component } from 'react';
import { connect } from 'react-redux';

import { sendContato } from './../../actions';

import {
    Aviso,
    AlertIcon,
    ErrorIcon,
    CheckIcon,
    MessageContainer,
    ContatoInput,
    ContatoSelect,
    ContatoTextArea,
    SendBtn,
    MsgFeedback
} from './contato-styled';

// Colors [TEMP]
import { Theme } from './../commons/vitrine-colors';
import { analyticsEvent } from '../analytics-gjc/analytics-gjc-mockup';
import { sendAnalytics } from '../analytics-gjc/analytics-gjc';

class Mensagem extends Component {

    constructor(props) {

        super(props);

        this.state = {
            tipoContatos: [
                { descricao: 'Email', value: 'email', mask: null },
                { descricao: 'Telefone', value: 'telefone', mask: '(99)99999-9999' },
                { descricao: 'Whatsapp', value: 'whatsapp', mask: '(99)99999-9999' }
            ],
            vitrine: 'negocios',
            isVerified: false,
            showError: false,
            showSuccess: false,
            showErrorMsg: false,
            enviandoMsg: false,
            nome: '',
            tipoContato: '',
            contato: '',
            mensagem: '',
            entrarContato: true,
            msgBoxTouched: false,
            inputMask: null
        }

        this.handleNome = this.handleNome.bind(this);
        this.handleContato = this.handleContato.bind(this);
        this.handleMensagem = this.handleMensagem.bind(this);
        this.selectTipoContato = this.selectTipoContato.bind(this);
        this.hideFeedback = this.hideFeedback.bind(this);
        this.sendMsg = this.sendMsg.bind(this);
        this.validateTelephone = this.validateTelephone.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    componentDidUpdate(prevProps) {

        if (this.props.defaultMsg && this.props.defaultMsg != prevProps.defaultMsg) {
            let newMsg = `Olá! Quero saber mais sobre o anúncio: ${this.props.defaultMsg}`;

            this.setState({ mensagem: newMsg });
        }

        if (this.props.useCurriculoPerfil && !this.state.enviandoMsg) {

            this.setState({ enviandoMsg: true });
            this.props.setUseCurriculoPerfil(false);

        }

    }

    handleNome(e) {
        e.preventDefault();
        this.setState({ nome: e.target.value })
    }

    handleContato(e) {

        e.preventDefault();

        switch (this.state.tipoContato) {
            case 'email':
                break;

            case 'telefone':
                break;

            case 'whatsapp':
                break;

            default:
                break;
        }

        this.setState({ contato: e.target.value })
    }

    handleMensagem(e) {

        const { analyticsJson } = this.props;
        const { msgBoxTouched } = this.state;

        e.preventDefault();

        this.setState({ mensagem: e.target.value, msgBoxTouched: true })

    }

    selectTipoContato(value) {
        this.setState({ tipoContato: value, inputMask: value.mask, contato: '' });
    }

    hideFeedback(e) {
        e.preventDefault();
        this.setState({ showErrorMsg: false, showSuccess: false, nome: '', contato: '', tipoContato: '', mensagem: '' });
    }

    validateTelephone(phone) {
        let re =
            /^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;
        return re.test(phone.replaceAll("-", "").replaceAll("_", ""));
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    sendMsg() {

        const { analyticsJson } = this.props;
        const { nome, contato, mensagem, tipoContato } = this.state;

        sendAnalytics(analyticsEvent.ENVIAR_MENSAGEM, analyticsJson);

        let contatoError = false;

        if (tipoContato.value == "email") {
            contatoError = !this.validateEmail(contato);
        } else if (tipoContato.value == "telefone" || tipoContato.value == "whatsapp") {
            contatoError = !this.validateTelephone(contato);
        } else {
            contatoError = true;
        }

        if (!nome || !contato || !mensagem || contatoError) {
            this.setState({ showError: true });
        } else {

            const msg = {
                idAnuncio: this.props.anuncioId,
                nome: nome,
                email: contato,
                telefone: contato,
                texto: mensagem,
                tipoContatoMensagem: tipoContato.value.toUpperCase()
            }

            this.setState({ enviandoMsg: true });

            this.props.sendContato({
                mensagem: msg
            }).then(response => {
                this.setState({ showSuccess: true, enviandoMsg: false });
                sendAnalytics(analyticsEvent.MENSAGEM_ENVIADA, analyticsJson);
            }).catch(error => {
                this.setState({ showErrorMsg: true, enviandoMsg: false });
            });

        }

    }

    render() {

        const { vitrine, windowWidth } = this.state;

        return (

            <div>

                <MessageContainer>

                    <Aviso showError={this.state.showError}>

                        <AlertIcon />

                        <p>
                            Atenção, verifique se todos os campos estão
                            preenchidos corretamente.
                        </p>

                    </Aviso>

                    <ContatoInput
                        placeholder="Nome"
                        value={this.state.nome}
                        vitrinecolor={Theme[vitrine].main}
                        onChange={this.handleNome} />

                    <ContatoSelect
                        placeholder='Quero que entrem em contato via:'
                        value={this.state.tipoContato}
                        getOptionLabel={(option) => option.descricao}
                        options={this.state.tipoContatos}
                        vitrinecolor={Theme[vitrine].main}
                        onChange={this.selectTipoContato} />

                    <ContatoInput
                        placeholder="Contato"
                        value={this.state.contato}
                        vitrinecolor={Theme[vitrine].main}
                        onChange={this.handleContato}
                        disabled={this.state.tipoContato == ''}
                        mask={this.state.inputMask} />

                    <ContatoTextArea
                        vitrinecolor={Theme[vitrine].main}
                        value={this.state.mensagem}
                        onChange={this.handleMensagem} />

                    <SendBtn id="enviar_mensagem" variant="contained" vitrinecolor={Theme[vitrine].main} onClick={this.sendMsg}>ENVIAR</SendBtn>

                    <MsgFeedback showMsg={this.state.enviandoMsg}>
                        <h1>Enviando!</h1>
                        <p>Aguarde...</p>
                    </MsgFeedback>

                    <MsgFeedback showMsg={this.state.showSuccess}>
                        <CheckIcon />
                        <h1>Enviado!</h1>
                        <SendBtn id="enviar_sucesso" variant="contained" vitrinecolor={Theme[vitrine].main} onClick={this.hideFeedback}>Voltar</SendBtn>
                    </MsgFeedback>

                    <MsgFeedback showMsg={this.state.showErrorMsg}>
                        <ErrorIcon />
                        <h1>Erro ao enviar!</h1>
                        <SendBtn variant="contained" vitrinecolor={Theme[vitrine].main} onClick={this.hideFeedback}>Voltar</SendBtn>
                    </MsgFeedback>

                </MessageContainer>

            </div>
        );

    };

}

const mapStateToProps = store => ({
    storedUserFiles: store.user.storedUserFiles,
    authentication: store.authentication.authentication
});

export default connect(mapStateToProps, { sendContato })(Mensagem)
