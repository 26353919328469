import {
  FETCH_CATEGORIES_BY_SECTION,
  FETCH_ANUNCIOS_LIST,
  FETCH_ANUNCIO_BY,
  FETCH_CURRENT_USER,
  DELIVER_DEFAULT_USER,
  STORE_USER_FILES,
  FETCH_CATEGORIES_LIST,
  FETCH_STATE_LIST,
  FETCH_CITY_LIST,
  FETCH_MOTIVOS_LIST,
  FETCH_RELACIONADOS
} from './types';

import axios from 'axios';
// Página de busca
export const fetchAnunciosList = filtro => async (dispatch, getState, api) => {
  let header = null;
  let requestContext = undefined;

  if (typeof window !== 'undefined') {
    if (window.keycloak && window.keycloak.token) {
      header = {
        'Authorization': `Bearer ${window.keycloak.token}`
      };
    }
  }

  await axios.post(`${process.env.API_URL}/vitrine-negocios/consultaVitrineNegocios?`, filtro, {
    headers: header ? header : {}
  })
    .then(response => {
      if (Object.keys(response.data).length > 0) {
        let anuncioList = response.data;

        if (anuncioList.categoriasPai) {
          anuncioList.categoriasPai.unshift({ descricao: 'Todos', valor: null });
        }

        if (anuncioList.categorias) {
          anuncioList.categorias.unshift({ descricao: 'Todos', valor: null });
        }

        if (anuncioList.estados) {
          anuncioList.estados.unshift({ descricao: 'Todos', valor: null })
        }

        if (anuncioList.cidades) {
          anuncioList.cidades.unshift({ descricao: 'Todos', valor: null })
        }

        dispatch({
          type: FETCH_ANUNCIOS_LIST,
          payload: { ...response.data, ...{ 'filtroSetup': filtro } }
        });
      }
    }, error => {
      requestContext = Object.assign({}, {
        error: error.response.data
      });
    });

  return requestContext;
};

export const selectCategoria = value => ({
  type: 'CLICK_UPDATE_ANUNCIO',
  selectedCategoria: value
});

// Página do anúncio
export const fetchAnunciosBy = anuncioId => async (dispatch, getState, api) => {
  let requestContext = undefined;

  let header = null;

  if (typeof window !== 'undefined') {
    if (window.keycloak && window.keycloak.token) {
      header = {
        'Authorization': `Bearer ${window.keycloak.token}`
      };
    }
  }

  await api.get(`${process.env.API_URL}/anuncio-digital/find/${anuncioId}`, {
    headers: header ? header : {}
  })
    .then(response => {
      dispatch({
        type: FETCH_ANUNCIO_BY,
        payload: response.data
      });
    })
    .catch(error => {
      requestContext = Object.assign({}, {
        error: error.response.data
      });
    });

  return requestContext; //Somente em caso de erro que há retorno de objeto
};

export const fetchAnunciosRelacionados = filtro => async (dispatch, getState, api) => {
  let requestContext = undefined;

  const { idAnuncio, qtdAnuncios } = filtro;

  await axios.get(`${process.env.API_URL}/vitrine-negocios/consultarAnunciosSimilares?idAnuncio=${idAnuncio}&qtdAnuncios=${qtdAnuncios}`)
    .then(response => {
      dispatch({
        type: FETCH_RELACIONADOS,
        payload: response.data
      })
    })

  return requestContext;
};

export const fetchCategoriesBySection = () => async (dispatch, getState, api) => {
  const res = await api.get('/categoriessection');

  dispatch({
    type: FETCH_CATEGORIES_BY_SECTION,
    payload: res
  });
};

export const fetchCategories = () => (dispatch, getState, api) => {
  axios.get(`${process.env.API_URL}/vitrine-negocios/consultarCategoriasPaiPorSecao`)
    .then(response => {
      if (Object.keys(response.data).length > 0) {
        dispatch({
          type: FETCH_CATEGORIES_LIST,
          payload: response.data
        });
      }
    }, error => {
      throw error
    });
};

export const fetchStates = () => (dispatch, getState, api) => {
  axios.get(`${process.env.API_URL}/vitrine-negocios/consultaEstados`)
    .then(response => {
      if (Object.keys(response.data).length > 0) {
        dispatch({
          type: FETCH_STATE_LIST,
          payload: response.data
        });
      }
    }, error => {
      throw error
    });
};

export const fetchCities = state => (dispatch, getState, api) => {
  axios.get(`${process.env.API_URL}/vitrine-negocios/consultaCidadesPeloEstado?estado=${state}`)
    .then(response => {
      if (Object.keys(response.data).length > 0) {
        dispatch({
          type: FETCH_CITY_LIST,
          payload: response.data
        });
      }
    }, error => {
      throw error
    });
};

export const sendContato = msg => (dispatch, getState, api) =>
  new Promise((resolve, reject) => {
    const { mensagem } = msg;

    console.log(mensagem);

    axios.post(`${process.env.API_URL}/vitrine-negocios/sendMessage`, mensagem)
      .then(response => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });

export const fetchMotivos = () => (dispatch, getState, api) => {
  axios
    .get(`${process.env.API_URL}/vitrine-negocios/findMotivosDenuncia`)
    .then(response => {
      if (response.data.length > 0) {
        dispatch({
          type: FETCH_MOTIVOS_LIST,
          payload: response.data
        });
      }
    });
};

// Authentication
export const getUser = () => async (dispatch, getState, api) => {
  await api.get('/accounts').then(response => {
    dispatch({
      type: FETCH_CURRENT_USER,
      payload: response.data
    });
  }).catch(err => {
    // dispatch({ type: DELIVER_DEFAULT_USER });
    throw new Error(err);
  });
}

// TODO: tratar erro de logout
// export const userLogout = () => async (dispatch, getState, api) => {
//   await api.get('/logout').then( () => {
//     dispatch({ type: DELIVER_DEFAULT_USER });
//   }).catch(err => {
//     throw new Error(err);
//   });
// }

export const userLogout = () => async (dispatch, getState, api) => {
  dispatch({ type: DELIVER_DEFAULT_USER });
  await api.get('/logout');
};

export const storeUserFiles = userFiles => dispatch => {
  if (userFiles.length > 0) {
    dispatch({
      type: STORE_USER_FILES,
      payload: userFiles
    })
  }
};