import React from 'react';
import { Bling as GPT } from "react-gpt";
import HOCMediaQueries from './../hocs/HOCMediaQueries';
import { Hidden } from '@material-ui/core';

const gptStyle = {
  marginTop: '10px',
  marginBottom: '15px',
  textAlign: 'center'
};

const gptType = {
  intervencao: {
    id: 'div-gpt-ad-1513338245081-6',
    targeting: { tipo: 'capa', editoria: 'negocios', ambiente: process.env.DFP_ENVIROMENT },
    path: '/54600456/Classi_v_capa_DHTML_940x600',
    mapping: [940, 600]
  },
  intervencao_mobile: {
    id: 'div-gpt-ad-1513338245081-61',
    targeting: { tipo: 'capa', editoria: 'negocios', ambiente: process.env.DFP_ENVIROMENT },
    path: '/54600456/Classi_v_capa_DHTML_300x250',
    mapping: [300, 250]
  },
  maxiboard: {
    id: 'div-gpt-ad-1513338245081-2',
    targeting: { tipo: 'capa', editoria: 'negocios', ambiente: process.env.DFP_ENVIROMENT },
    path: '/54600456/Classi_v_capa_970x250',
    mapping: [970, 250]
  },
  small_maxiboard: {
    id: 'div-gpt-ad-1513338245081-1',
    targeting: { tipo: 'capa', editoria: 'negocios', ambiente: process.env.DFP_ENVIROMENT },
    path: '/54600456/Classi_v_capa_970x150',
    mapping: [970, 150]
  },
  megabox: {
    id: 'div-gpt-ad-1513340433930-3',
    targeting: { tipo: 'capa', editoria: 'negocios', ambiente: process.env.DFP_ENVIROMENT },
    path: '/54600456/Classi_v_busca_600x300',
    mapping: [600, 300]
  },
  arroba: {
    id: 'div-gpt-ad-1513340433930-4',
    targeting: { tipo: 'capa', editoria: 'negocios', ambiente: process.env.DFP_ENVIROMENT },
    path: '/54600456/Classi_v_busca_300x250',
    mapping: [300, 250]
  }
};

class GPTComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewable: false
    };

    this.isViewable = this.isViewable.bind(this);
  }

  isViewable() {
    this.setState({ viewable: true });
  };

  typeOfGPT(name) {
    const { screenMobile } = this.props;
    let type;

    if (name === 'intervencao') {
      type = screenMobile ? 'intervencao_mobile' : name;
    } else {
      type = screenMobile ? 'arroba' : name;
    }

    return type;
  }

  render() {
    const { screenMobile, gpt, gptId } = this.props;
    const { viewable } = this.state;
    let type = this.typeOfGPT(gpt);
    const isOutOfPage = gpt === 'intervencao' ? true : false;

    return (
      <div className={gpt === 'intervencao' && viewable ? "intervencao-holder" : null}>
        <GPT
          id={gptId}
          style={gptStyle}
          targeting={gptType[type].targeting}
          adUnitPath={gptType[type].path}
          slotSize={gpt !== 'intervencao' ? gptType[type].mapping : null}
          forceSafeFrame
          collapseEmptyDiv
          outOfPage={isOutOfPage}
          renderWhenViewable={gpt === 'intervencao' ? false : true}
          onImpressionViewable={this.isViewable}
        />
      </div>
    )
  }
}

export default HOCMediaQueries(GPTComponent);