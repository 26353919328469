import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchCategories } from './../../actions';
import { UrlsClassiUtils } from '../../resources/methods/UrlsClassiUtils';
import { StringUtils } from '../../resources/methods/StringUtils';

// Components
import { Container, VitrineTitulo, LogoVitrine, BannerContainer } from './../../components/commons/theme-styled';
import GPTComponent from './../../components/gpt/GPT';
import Loading from '../../components/loading/loading';
import Topbar from './../../components/topbar/topbar';
import { sendAnalytics, enviarPageView } from './../../components/analytics-gjc/analytics-gjc';
import { analyticsEvent } from './../../components/analytics-gjc/analytics-gjc-mockup';
import { Helmet } from 'react-helmet';
import BlogFeed from '../../components/blog-feed/blog-feed';

// Images
import LogoNegocios from '../../resources/svg/logos/logo-classi-negocios.svg';
import BgNegocios from '../../resources/svg/bg-vitrines/fundo-negocios.png';
import ClassiLogo from './../../resources/svg/logo-classi.svg';

// Colors
import { Theme } from '../../components/commons/vitrine-colors';

// Styles
import {
  FilterContainer,
  FilterLabel,
  FilterInput,
  Filter,
  BuscaBtn,
  FilterSelect,
} from './../../components/commons/filter-styled';

import { initAnalytics } from '../../../helpers/analytics';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

class Home extends Component {

  constructor(props) {

    super(props);

    this.state = {
      categorias: [],

      categoria: '',
      categoriaDescricao: '',
      idCategoria: '',
      query: '',
      nextUrl: '#',
      vitrine: 'negocios',
      showLoading: true
    }

    this.handleInput = this.handleInput.bind(this);
    this.selectCategoria = this.selectCategoria.bind(this);
    this.analyticsBusca = this.analyticsBusca.bind(this);

  }

  componentDidMount() {

    this.props.fetchCategories();

    this.setState({ showLoading: false });

    // Evento Analytics
    initAnalytics('vitrine_negocios_capa', process.env.SITEID_ANALYTICS);
    enviarPageView();

  }

  componentDidUpdate(prevProps) {

    const { categoryList } = this.props;

    if (categoryList !== prevProps.categoryList) {
      this.setState({ categorias: categoryList })
    }

  }

  selectCategoria(value) {
    this.setState({ categoriaDescricao: value.descricao, idCategoria: value.valor });
  }

  analyticsBusca() {
    const texto = this.state.query, idCategoria = this.state.idCategoria;

    // Evento Analytics
    sendAnalytics(analyticsEvent.BUSCAR, { texto: texto, categoria: idCategoria })
  }

  handleInput(e) {
    e.preventDefault();

    this.setState({ query: e.target.value });
  }

  render() {
    const { query, showLoading } = this.state;

    return (
      <React.Fragment>

        <Helmet>
          <title> Classi - Negócios </title>

          {/* Google  */}
          <meta property="title" content="Classificados de serviços e negócios - Classi"></meta>
          <meta property="type" content="website"></meta>
          <meta property="url" content={process.env.BASE_URL}></meta>
          <meta property="image" content={process.env.BASE_URL + ClassiLogo}></meta>
          <meta property="description" content="Os melhores negócios estão no Classificados mais tradicional de Goiás. Encontre eletrônicos, eletrodomésticos, móveis, serviços e muito mais."></meta>
          <meta property="site_name" content="Classi"></meta>

          {/* Open Graph data  */}
          <meta property="og:title" content="Classificados de serviços e negócios - Classi"></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content={process.env.BASE_URL}></meta>
          <meta property="og:image" content={process.env.BASE_URL + ClassiLogo}></meta>
          <meta property="og:description" content="Os melhores negócios estão no Classificados mais tradicional de Goiás. Encontre eletrônicos, eletrodomésticos, móveis, serviços e muito mais."></meta>
          <meta property="og:site_name" content="Classi"></meta>
          <meta property="fb:admins" content="177140826180797"></meta>

          {/* Twitter */}
          <meta name="twitter:card" content="summary"></meta>
          <meta name="twitter:site" content="@classi_br"></meta>
          <meta name="twitter:title" content="Classificados de serviços e negócios - Classi"></meta>
          <meta name="twitter:description" content="Os melhores negócios estão no Classificados mais tradicional de Goiás. Encontre eletrônicos, eletrodomésticos, móveis, serviços e muito mais."></meta>
          <meta name="twitter:creator" content="@classi_br"></meta>
          <meta name="twitter:image:src" content={process.env.BASE_URL + ClassiLogo}></meta>
        </Helmet>

        <Container direction='column' noPadding={true}>

          <Topbar withAnchor={false} backgroundColor={Theme[vitrineType].main} />

          {showLoading && <Loading />}

          {/* Intervenção */}
          <GPTComponent gtpId="intervencao" gpt="intervencao" />

          <Container firstContainer={true} direction="row" justify="space-around">
            <VitrineTitulo vitrinecolor={Theme[vitrineType].main}>Aqui é rápido e fácil para comprar e vender</VitrineTitulo>
          </Container>

          <Container backgroundImg={BgNegocios} direction="row">

            <LogoVitrine src={LogoNegocios} />

            <FilterContainer direction="column">

              <Filter noBorder direction="row">

                <FilterLabel>Qual categoria: </FilterLabel>

                <FilterSelect
                  placeholder='Selecione a categoria'
                  options={this.state.categorias}
                  vitrinecolor={Theme[vitrineType].main}
                  isSearchable={false}
                  getOptionLabel={(option) => option.descricao}
                  onChange={(option) => this.selectCategoria(option)}
                  defaultSearchSize></FilterSelect>
              </Filter>

              <Filter noBorder direction="row">
                <FilterLabel>Palavra-chave: </FilterLabel>
                <FilterInput placeholder="O que você procura?" value={this.state.query} onChange={this.handleInput} />
              </Filter>

              <BuscaBtn
                buscacolor="#04bcd5"
                to={{
                  pathname: `/busca${UrlsClassiUtils.constructFiltrosPathname(null, null, this.state.categoriaDescricao)}`,
                  search: `?pagina=0${query ? '&query=' + query : ''}`,
                  state: { 'categoria': this.state.categoriaDescricao ? StringUtils.cleanString(this.state.categoriaDescricao) : null }
                }}
                onClick={this.analyticsBusca}>
                BUSCAR
              </BuscaBtn>

            </FilterContainer>

          </Container>

          <BannerContainer>
            <GPTComponent gpt="small_maxiboard" />
          </BannerContainer>

        </Container>

      </React.Fragment>

    );
  }
}

const mapStateToProps = store => ({
  categoryList: store.search.categoryList
});

function loadData(store) {
  return store.dispatch(fetchCategories());
}

export default {
  loadData,
  component: withRouter(connect(mapStateToProps, { fetchCategories })(Home))
};