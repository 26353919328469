import React from 'react';
import PropTypes from 'prop-types';

// Components
import Favoritar from '../favoritar/favoritar';

// Styles
import {
  CardContainer,
  CardPrioridade,
  CardTituloContainer,
  CardTitulo,
  CardInfo,
  CardSubtitulo,
  CardBtn,
  CardPreco,
  CardLink,
  CardImageContainer,
  CardImage,
  CardInfoContainer
} from './card-anuncio-styled';

// Images
import NegociosSVG from './../../resources/svg/vitrines/negocios.svg';
import NegociosSVG_White from './../../resources/svg/vitrines/white/negocios-white.svg';

import { Hidden } from '@material-ui/core';
import { sendAnalytics } from '../analytics-gjc/analytics-gjc';
import { analyticsEvent } from '../analytics-gjc/analytics-gjc-mockup';

class CardAnuncio extends React.Component {
  constructor(props) {
    super(props);

    this.sendCardClick = this.sendCardClick.bind(this);
  }

  getDefaultImg = () => {
    switch( this.props.vitrine ){
      case 'IMOVEL_VENDA':
        return { img: ImovelVendaSVG, imgWhite: ImovelVendaSVG_White };
      case 'IMOVEL_ALUGUEL':
        return { img: ImovelAluguelSVG, imgWhite: ImovelAluguelSVG_White };
      case 'VEICULOS':
        return { img: CarrosSVG, imgWhite: CarrosSVG_White };
      case 'MOTOS':
        return { img: MotosSVG, imgWhite: MotosSVG_White };
      case 'AGRONEGOCIO':
        return { img: AgroSVG, imgWhite: AgroSVG_White };
      case 'NEGOCIOS':
        return { img: NegociosSVG, imgWhite: NegociosSVG_White };
      case 'EMPREGOS':
        return { img: EmpregosSVG, imgWhite: EmpregosSVG_White };
      default:
        return NegociosSVG;
    }
  }

  componentDidMount() {
    sendAnalytics(analyticsEvent.IMPRESSAO_CARD_ANUNCIO, { idAnuncio: this.props.idAnuncio });
  }

  sendCardClick() {
    sendAnalytics(analyticsEvent.CLICK_ANUNCIO, { idAnuncio: this.props.idAnuncio });
  }

  render(){
    const defaultImg = this.getDefaultImg();
    const{ url, categoria, authentication, thumbnail, titulo, localizacao, isFavorite, idAnuncio, valor, prioridade } = this.props;

    return (
        <CardContainer className={`${prioridade.toLowerCase()}`} vitrinecolor={ '#ea8b1c' } onClick={this.sendCardClick}>
          <CardImageContainer>
            <CardImage to={{ pathname: url ? url : '/404' }} target='_blank' src={ thumbnail }/>
          </CardImageContainer>

          <CardInfoContainer>
            <CardPrioridade className={`${prioridade.toLowerCase()}`} vitrinecolor={ '#ea8b1c' }>
              { prioridade == 'MEDIA' && <span>DESTAQUE</span> }
              { prioridade == 'ALTA' && <span>SUPER DESTAQUE</span> }
            </CardPrioridade>

            <Favoritar 
              isFavorite={ isFavorite } 
              idAnuncio={ idAnuncio } 
              authentication={ authentication } 
            />

            <CardInfo>
              <CardTituloContainer>
                <CardTitulo to={{ pathname: url ? url : '/404' }} target='_blank'>{titulo}</CardTitulo>
                <br />
                <CardSubtitulo to={{ pathname: url ? url : '/404' }} target='_blank'>{localizacao}</CardSubtitulo>
                <CardSubtitulo to={{ pathname: url ? url : '/404' }} target='_blank'>{categoria}</CardSubtitulo>
              </CardTituloContainer>
              
              { valor && <CardPreco><small>R$</small> {valor}</CardPreco> } 
              { !valor && <CardPreco>A Combinar</CardPreco> } 

              <Hidden smDown>
                <CardLink to={{ pathname: url ? url : '/404' }} target='_blank'>
                  <CardBtn vitrinecolor={ '#ea8b1c' }>      
                    Ver detalhes
                  </CardBtn>
                </CardLink>
              </Hidden>
            </CardInfo>

          </CardInfoContainer>
        </CardContainer>
    )
  }
}

CardAnuncio.propTypes = {
  vitrine: PropTypes.string,
  vitrineNome: PropTypes.string,
  titulo: PropTypes.string,
  descricao: PropTypes.string,
  localizacao: PropTypes.string,
  valor: PropTypes.number,
  thumbnail: PropTypes.string,
  url: PropTypes.string,
  detalhes: PropTypes.object,
  isFavorite: PropTypes.bool
};

CardAnuncio.defaultProps = {
  prioridade: 'BAIXA'
}

export default CardAnuncio;