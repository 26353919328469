import React, { Component } from 'react';
import { withRouter } from 'react-router';

//Components
import { Container } from './../../components/commons/theme-styled';
import Topbar from './../../components/topbar/topbar';

// //Styles
import { Logo, ErroContent, Titulo, Subtitulo } from './Error50x-styled';

//Imgs
import LogoClassi from '../../resources/svg/logo-classi.svg';

// Colors
import { Theme } from '../../components/commons/vitrine-colors';

// Analytics
import { initAnalytics } from "../../../helpers/analytics";
import { enviarPageView } from "./../../components/analytics-gjc/analytics-gjc";

import ErroAlias from './ErroAlias';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

class Error50x extends Component {

    constructor(props) {
        super(props);

        if (props.staticContext) {
            props.staticContext.error50x = true;
        }
        
    }

    getTipoErro() {

        let tipoErro = '50x';
        let uriRegex = /context=\".*\"/;

        //Garante execução de código somente no client-side.
        if (this.props.location.search) {

            let uri = this.props.location.search;

            //Decode URI para regex
            if (/\%22|\%27|\%25/g.test(uri)) {
                uri = uri.replace(/(%(?:2[257]))/g, decodeURI);
            }

            //Decodifica o erro
            let code = uri.match(uriRegex);

            if (code.length) {

                code = code[0];
                code = code.replace('context="', '');
                code = code.replace(/"/g, '');

                if (window && window.atob) {
                    code = window.atob(code);
                }

                if (ErroAlias[code]) {
                    tipoErro = ErroAlias[code];
                }

            }

        }

        return tipoErro;
    }

    componentDidMount() {

        initAnalytics('vitrine_negocios_500', process.env.SITEID_ANALYTICS);
        enviarPageView();

    }

    render() {

        return (
            <Container backgroundColor="#400f50" firstContainer={true} direction="column" noPadding={true}>
                <Topbar withAnchor={false} backgroundColor={ Theme[vitrineType].main }/>
                <ErroContent>
                    <Logo src={LogoClassi} />
                    <Titulo>OPS!</Titulo>
                    <Subtitulo>Erro: {this.getTipoErro()} </Subtitulo>
                </ErroContent>
            </Container>
        );

    }

}

export default {
    component: withRouter(Error50x)
}
