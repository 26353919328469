import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import authReducer from './authReducer';
import anuncioReducer from "./anuncioReducer";

import searchReducer from './searchReducer';

export default combineReducers({
  user: usersReducer,
  anuncio: anuncioReducer,
  authentication: authReducer,
  search: searchReducer,
  selectCategoria: searchReducer
});
