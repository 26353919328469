// TODO: Colocar secao para pegar do .env

export const mockupFiltro = {
    cargos: null,
    categoria: null,
    categoriaPai: null,
    cidade: null,
    estado: null,
    experienciaMinima: null,
    formacaoMinima: null,
    ordenacao: "RELEVANCIA",
    pagina: 1,
    qtdRegistros: 6,
    secao: "NEGOCIOS",
    tipoNegociacao: null
}

export const searchFilterList = {
    roleType: {
        name: 'roleTypeFilter',
        label: 'Tipo de vaga:',
        placeholder: 'Adicionar',
        filterName: 'tipoVaga'
    },
    officeOrFunction: {
        name: 'officeOrFunction',
        label: 'Cargo ou função:',
        placeholder: 'Digite o que procura',
        filterName: 'cargo'
    },
    categoriaPai: {
        name: 'categoriaPaiFilter',
        label: 'Categoria:',
        filterName: 'categoriaPai',
        placeholder: 'Selecione a categoria'
    },
    category: {
        name: 'categoryFilter',
        label: 'Subcategoria',
        filterName: 'categoria',
        placeholder: 'Selecione a subcategoria'
    },
    state: {
        name: 'stateFilter',
        label: 'Estado:',
        placeholder: 'Selecione o estado',
        filterName: 'estado'
    },
    city: {
        name: 'cityFilter',
        label: 'Cidade:',
        placeholder: 'Selecione a cidade',
        filterName: 'cidade'
    },
    scholarity: {
        name: 'scholarityFilter',
        label: 'Escolaridade:',
        placeholder: 'Selecione',
        filterName: 'formacaoMinima'
    },
    experience: {
        name: 'experienceFilter',
        label: 'Experiência:',
        placeholder: 'Selecione',
        filterName: 'experienciaMinima'
    },
    query: {
        name: 'query',
        label: 'O que você procura?',
        placeholder: 'O que você procura?',
        filterName: 'query'
    },
    vlrMinimo:  {
        name: 'valor-minimo',
        placeholder: 'Minimo',
        filterName: 'vlrMinimo'
    },
    vlrMaximo: {
        name: 'valor-maximo',
        placeholder: 'Máximo',
        filterName: 'vlrMaximo'
    }

}