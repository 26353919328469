import styled from "styled-components";
import { darken } from "@material-ui/core/styles/colorManipulator";
import Button from "@material-ui/core/Button";
import Search from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";

export const HiddenControl = styled.div`
    display: ${props =>
        props.desktop ? "none" : props.display ? props.display : "flex"};
    width: 100%;
    color: #fff;

    @media screen and (max-width: 768px) {
        display: ${props =>
        props.mobile ? "none" : props.display ? props.display : "flex"};
    }
`;

export const TopbarContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: ${props => props.backgroundColor};
    border-bottom: ${props =>
        props.backgroundColor != "#FFF"
            ? "6px solid " + darken(props.backgroundColor, 0.3)
            : "null"};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
`;

export const TopbarContent = styled.div`
    width: 100%;
    max-width: 1024px;
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    z-index: 99;

    @media screen and (max-width: 768px) {
        justify-content: space-between;

        .login-btn {
            margin-left: auto;
            margin-right: 10px;
        }
    }
`;

export const TopbarWrapper = styled.div`
    width: 100%;
    height: auto;

    max-height: ${props => (props.isopen ? "1000px" : "0")};

    overflow: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: url(${props => props.bgimage});
    background-color: #fff;

    opacity: ${props => (props.isopen ? "1" : "0")};
    transition: max-height 0.5s ease;

    padding-top: ${props => (props.isopen ? "40px" : "0")};

    @media screen and (max-width: 768px) {
        display: block;

        position: fixed;
        top: 56px;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export const TopbarWrapperIcon = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
`;

export const TopbarFilter = styled(TopbarWrapper)`
    padding: ${props => (props.isopen ? "20px" : "0")};
    padding-top: ${props => (props.isopen ? "40px" : "0")};
`;

export const LinkContainer = styled(Link)`
    width: 104px;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -52px;
        margin-right: -52px;
    }
`;

export const Logo = styled.img.attrs(props => ({
    width: '103',
    height: '30'
}))``;

export const AnchorContainer = styled.div`
    width: auto;
    min-width: 300px;
    display: flex;
    margin-left: auto;
    margin-right: 0;

    button {
        margin-right: 16px;
        &:last-of-type {
            margin-right: 0;
        }
    }

    .first {
        margin-right: 40px;
    }

    .last {
        margin-left: 40px;
        margin-right: 40px;
    }
`;

export const TituloBtn = styled.p`
    font-size: 12px;
    text-transform: initial;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
`;

export const Subtitulo = styled.p`
    font-size: 14px;
    font-weight: bold;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 0;
`;

export const Divisor = styled.div`
    width: 90%;
    border-top: 2px solid #a1d4d8;
    margin-top: 20px;
`;

export const SignupBtn = styled(Button)`
    && {
        width: 300px;
        height: 60px;
        background-color: #9b43c4;
        color: #fff;
        margin-bottom: 20px;

        span {
            font-size: 18px;
        }
    }
`;

export const Overlay = styled.div`
    display: ${props => (props.isopen ? "block" : "none")};

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
`;

export const SearchIcon = styled(Search)`
    && {
        font-size: 30px;
        color: #fff;
        margin-left: 20px;
        margin-right: 10px;
    }
`;

export const SearchCloseIcon = styled(CloseIcon)`
    && {
        font-size: 30px;
        color: #fff;
        margin-left: 20px;
        margin-right: 10px;
    }
`;

export const TopbarFilterBtn = styled(Button)`
    && {
        width: 100%;
        color: #fff;
        font-size: 16px;
        background-color: ${props => props.btncolor};
        border-radius: 0;
        margin-top: 10px;

        &:hover {
            background-color: ${props => darken(props.btncolor, 0.3)};
        }
    }
`;
