import styled from 'styled-components';

export const LoadingAnimate = styled.div`
    width: 200px;
    height: 130px;

    background: url(${props => props.img});
    background-repeat: no-repeat;
`;

export const LoadingContainer = styled.div`
    background-color: #e5e5e5;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;

export const LoadingLogo = styled.img`
    width: 200px;
`;

export const LoadingLabel = styled.p`
    font-size: 21px;
    color: #FFF;
`;