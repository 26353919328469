import React from 'react';

export const Theme = {
    imoveis: {
        main: '#04bcd5'
    },
    carros: {
        main: '#cd181e'
    },
    motos: {
        main: '#940057'
    },
    agro: {
        main: '#009645'
    },
    negocios: {
        main: '#ea8b1c'
    },
    empregos: {
        main: '#f07226'
    }
};