import styled from "styled-components";

export const ContainerSliderAnuncio = styled.div`
    width: 100%;
    max-height: 350px;

    @media screen and (max-width: 768px){
        width: calc(100% - 20px);
    }

    .slick-slide{
        width: 25%;
    }

    .slick-slider{
        max-height: 320px;
    }

    .slick-arrow{
        color: rgba(0, 0, 0, .5);

        &:hover{
            color: rgba(0, 0, 0, .8);
        }
    }
`;