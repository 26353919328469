import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles/colorManipulator';
import ImageRender from "../../components/image-render/image-render";

import defaultImg from './../../resources/svg/logos/logo-classi-negocios.svg';
import bgImage from './../../resources/svg/bg-vitrines/fundo-negocios.png';

export const CardContainer = styled(Paper)`
    &&{
        height: 250px;

        width: 100%;
        max-width: 1024px;
        min-width: 300px;

        display: flex;
        flex-direction: row;

        position: relative;

        border-radius: 0;
        border-top: 3px solid ${props => props.vitrinecolor};
        margin-bottom: 20px;

        @media screen and (max-width: 768px){
            width: calc(100% - 20px);
            height: 150px;

            margin-left: auto;
            margin-right: auto;
        }

        &.media, &.alta{
        }

        &.alta{
            border-top: 0;
        }
    }
`;

export const CardImageContainer = styled.div`
    width: 280px;

    height: 100%;
    max-height: 350px;

    @media screen and (max-width: 768px){
        width: 140px;
    }
`;

export const CardImage = styled(props => 
    <Link to={{ pathname: props.url }} {...props}>
        <ImageRender src={props.src} width="300" height="300" className="image" />    
    </Link>)`
    &&{
        width: 100%;
        height: 100%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        
            background: url(${ defaultImg }), url(${ bgImage });
            background-position: 50%, 57%;
            background-repeat: no-repeat;
            background-size: 90%, auto 100%;
        }
    }
`;

export const CardInfoContainer = styled.div`
    width: calc(100% - 280px);
    height: auto;
    position: relative;

    @media screen and (max-width: 768px){
        width: calc(100% - 150px);
    }
`;

export const CardPrioridade = styled.div`
    width: 100%;
    height: 25px;
    padding: 2px 10px;
    text-transform: uppercase;
    
    &.media{
        color: ${props => props.vitrinecolor};
        background-color: #FFF;
    }

    &.alta{
        color: #FFF;
        background-color: ${props => props.vitrinecolor};
    }
`;

export const CardInfo = styled.div`
    width: 100%;
    height: calc(100% - 25px);
    max-height: 320px;

    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;
`;

export const CardTituloContainer = styled.div`
`;

export const CardTitulo = styled(Link)`
    &&{
        font-size: 31px;
        color: #4a4a4a;
    
        margin-top: 0;
        margin-bottom: 0;

        text-transform: capitalize;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 360px;

        display: block;
    
        @media screen and (max-width: 768px){
            font-size: 18px;
        }
    }
`;

export const CardSubtitulo = styled(Link)`
    &&{
        font-size: 18px;
        color: #626363;
    
        margin-top: 0;
        margin-bottom: 0; 
    
        @media screen and (max-width: 768px){
            font-size: 16px;
        }
    }
`;

export const CardItem = styled.div`
    text-align: center;
`;

export const CardPreco = styled.p`
    font-size: 31px;
    text-align: right;

    margin-top: 0;
    margin-right: 25px;

    position: absolute;
    top: 10px;
    right: 0;

    @media screen and (max-width: 1336px){
        word-break: break-word;
    }

    @media screen and (max-width: 768px){
        font-size: 18px;
        
        margin-top: 15px;
        margin-bottom: 0px;
        margin-right: 0;
        
        word-break: normal;

        top: inherit;
        bottom: 10px;
    }
`;

export const CardIconContainer = styled.div`
    width: 80%;
    margin-top: 10px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const CardBtn = styled(Button)`
    &&{
        width: auto;
        height: 35px;
        color: #FFF;
        background-color: ${props => props.vitrinecolor};
        border-radius: 0;
        margin-top: auto;
        margin-bottom: 5px;

        &:hover{
            background: ${props => darken(props.vitrinecolor, 0.1)};
        }

        a{ color: #FFF; }
    }
`;

export const CardIconLabel = styled.p`
    font-size: 16px;
    text-align: center;

    margin-top: 0;
    margin-bottom: 0;
`;

export const CardIcon = styled.img`
    width: 30px;
`;

export const CardFavoritar = styled.div`
    width: 50px;
    height: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #FFF;
    position: absolute;
    top: 0;
    right: 0;
`;

export const CardLink = styled(Link)`
    &&{
        display: flex;
        align-self: flex-end;
    }
`;