import React, { Component } from 'react';
import { fetchAnunciosBy, fetchMotivos, fetchAnunciosRelacionados } from './../../actions';
import { connect } from 'react-redux';

// Components
import {
  AnuncioContainer,
  Container,
  CustomSubtitulo,
  Separator,
  Text,
  ContainerAnuncio,
  EmailShared,
  FacebookShared,
  TwitterShared
} from './../../components/commons/theme-styled';

import TopBarInterno from './../../components/topbar-interno/topbar-interno';
import ReportarAnuncio from './../../components/reportar-anuncio/reportar';
import Contato from './../../components/contato/contato';
import ImageGallery from './../../components/image-gallery/imageGallery';
import { anuncioMockup, anuncioEventoAnalytics } from './../../resources/mockups/anuncio-mockup';
import GPTComponent from './../../components/gpt/GPT';
import Topbar from './../../components/topbar/topbar';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import Loading from "../../components/loading/loading";

// Icons
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

// Colors
import { Theme } from '../../components/commons/vitrine-colors';

import Favoritar from './../../components/favoritar/favoritar';

// Images
import ClassiLogo from './../../resources/svg/logo-classi.svg';
import SuggestCard from '../../components/suggest-card/SuggestCard';
import { ContainerSliderAnuncio } from './AnuncioPage-styled';

import { sendAnalytics, enviarPageView } from "../../components/analytics-gjc/analytics-gjc";
import { analyticsEvent } from "../../components/analytics-gjc/analytics-gjc-mockup";
import { initAnalytics } from '../../../helpers/analytics';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

const PrevArrow = props => {
  const { currentSlide, slideCount, ...arrowProps } = props;

  return <ArrowBackIosOutlinedIcon  {...arrowProps} />
}

const NextArrow = props => {
  const { currentSlide, slideCount, ...arrowProps } = props;

  return <ArrowForwardIosOutlinedIcon {...arrowProps} />
}

const settingsCarrossel = {
  centerPadding: "60px",
  speed: 500,
  arrows: true,
  draggable: false,
  dots: true,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        slidesToScroll: 4,
        slidesToShow: 4,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2,
        infinite: true,
      },
    }
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

class Anuncio extends Component {
  constructor(props) {
    super(props);

    this.sendShareEvent = this.sendShareEvent.bind(this);
  }

  state = {
    userEmail: '',
    isAuthenticated: false,
    isContactDialogOpen: false,
    showLoading: true, 
    analyticsJson: null
  }

  async componentDidMount() {
    
    const { match } = this.props;
    let anuncioId = anuncioMockup(match.params.id);

    initAnalytics(anuncioId, process.env.SITEID_ANALYTICS);
    await this.getContent();

  }

  async componentDidUpdate(prevProps) {

    const anuncioId = anuncioMockup(this.props.match.params.id);
    initAnalytics(anuncioId, process.env.SITEID_ANALYTICS);

    if (prevProps.location != this.props.location) {
      await this.getContent();
    }

    //Atualiza anuncio em caso de mudança de usuários.
    if (prevProps.authentication.userEmail !== this.props.authentication.userEmail) {
      this.props.fetchAnunciosBy(this.props.anuncioById.id);
    }

    if (prevProps != this.props) {
      this.setState({ showLoading: false });
    }

    this.setSlickParams();

  }

  async getContent() {

    const anuncioId = anuncioMockup(this.props.match.params.id);

    this.setState({ showLoading: true });

    await this.props.fetchAnunciosBy(anuncioId);
    this.props.fetchAnunciosRelacionados({ idAnuncio: anuncioId, qtdAnuncios: 8 });
    this.props.fetchMotivos();

    if (this.topContainer) {
      this.topContainer.scrollIntoView({ behavior: "smooth" });
    }

    this.setState({ analyticsJson: anuncioEventoAnalytics(this.props.anuncioById) });

    enviarPageView(this.state.analyticsJson);

  }

  setSlickParams = () => {

    const { anunciosRelacionados } = this.props;

    if(anunciosRelacionados.length <= 2) {

      settingsCarrossel.responsive[0].settings.slidesToScroll= anunciosRelacionados.length;
      settingsCarrossel.responsive[0].settings.slidesToShow = anunciosRelacionados.length;
      settingsCarrossel.responsive[1].settings.slidesToScroll= anunciosRelacionados.length;
      settingsCarrossel.responsive[1].settings.slidesToShow = anunciosRelacionados.length;

    } else if(anunciosRelacionados.length <= 4) {

      settingsCarrossel.responsive[0].settings.slidesToScroll= anunciosRelacionados.length;
      settingsCarrossel.responsive[0].settings.slidesToShow = anunciosRelacionados.length;

    }
  
  }

  renderOferece = anuncio => {

    if (anuncio && anuncio.caracteristicas) {

      let items = anuncio.caracteristicas.map((item, index) => {
        return `<li key="item-${index}">${item.descricao}</li>`
      })

      const list = `<ul>${items.join('')}</ul>`

      return list

    }

  }

  sendShareEvent() {
    sendAnalytics(analyticsEvent.COMPARTILHAR_FB_TW_EMAIL, this.state.analyticsJson);
  }

  // TODO: Remover valor do titulo interno
  render() {

    const { anuncioById, authentication, motivosList, anunciosRelacionados } = this.props;
    const { showLoading } = this.state;

    return (
      
      <React.Fragment>

        <Helmet>
          <title>{anuncioById.titulo}</title>

          {/* Facebook Open Graph */}
          <meta property="title" content={anuncioById.titulo}></meta>
          <meta property="type" content="website"></meta>
          <meta property="url" content={`${process.env.BASE_URL}${this.props.location.pathname}`}></meta>
          <meta property="image" content={process.env.BASE_URL + ClassiLogo}></meta>
          <meta property="description" content={anuncioById.descricao ? anuncioById.descricao.replace(/(<([^>]+)>)/ig, "").substring(0, 153) : ''}></meta>
          <meta property="site_name" content="Classi"></meta>

          {/* Facebook Open Graph */}
          <meta property="og:title" content={anuncioById.titulo}></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content={`${process.env.BASE_URL}${this.props.location.pathname}`}></meta>
          <meta property="og:image" content={process.env.BASE_URL + ClassiLogo}></meta>
          <meta property="og:description" content={anuncioById.descricao ? anuncioById.descricao.replace(/(<([^>]+)>)/ig, "").substring(0, 153) : ''}></meta>
          <meta property="og:site_name" content="Classi"></meta>
          <meta property="fb:admins" content="177140826180797"></meta>

          {/* Twitter */}
          <meta name="twitter:card" content="summary"></meta>
          <meta name="twitter:site" content="@classi_br"></meta>
          <meta name="twitter:title" content={anuncioById.titulo}></meta>
          <meta name="twitter:description" content={anuncioById.descricao ? anuncioById.descricao.replace(/(<([^>]+)>)/ig, "").substring(0, 153) : ''}></meta>
          <meta name="twitter:creator" content="@classi_br"></meta>
          <meta name="twitter:image:src" content={process.env.BASE_URL + ClassiLogo}></meta>

          <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        </Helmet>

        <Container direction="column" noPadding={true} ref={el => { this.topContainer = el; }}>

          <Topbar withAnchor={false} backgroundColor={Theme[vitrineType].main} />

          <TopBarInterno
            titulo={anuncioById.titulo}
            subtitulo={anuncioById.categoriaDigital && anuncioById.categoriaDigital.descricao}
            idAnuncio={anuncioById.id}
            valor={anuncioById.valor}
            codigo={anuncioById.codigo}
            isFavorite={anuncioById.favorito}
            authentication={authentication} />

          {/* Intervenção */}
          <GPTComponent gtpId="intervencao" gpt="intervencao" />

          {anuncioById &&
            <Container direction="row" align="flex-start" justify="center" paddingTopOnBreak="0">

              <AnuncioContainer>

                {anuncioById && anuncioById.imagens && anuncioById.imagens.length > 0 &&
                  
                  <ImageGallery
                    images={anuncioById.imagens}
                    analyticsJson={this.state.analyticsJson} />

                }

                <Container direction="row" align="flex-start" justify="center" firstContainer={false} noPadding={true} onlyMobile={true}>

                  <ContainerAnuncio>

                    <ContainerAnuncio
                      width="100%"
                      block={true}
                      height="79px"
                      top="12px"
                      right="0">

                        <Favoritar
                          isFavorite={anuncioById.favorito}
                          idAnuncio={anuncioById.id}
                          authentication={authentication}
                          isAnuncioPage={true}
                          analyticsJson={this.state.analyticsJson} />
                        
                        <CustomSubtitulo
                          align="flex-end"
                          margin="8px -4px 0 0">
                          Favoritar
                        </CustomSubtitulo>

                    </ContainerAnuncio>

                  </ContainerAnuncio>

                </Container>

                <Separator showSeparator={anuncioById.descricao} texto="Sobre o anúncio" vitrinecolor={Theme[vitrineType].main} />
                <Text dangerouslySetInnerHTML={{ __html: anuncioById.descricao }} />
                <GPTComponent gpt="megabox" />
                <Separator showSeparator={anuncioById.caracteristicas && anuncioById.caracteristicas.length} texto="Oferece" vitrinecolor={Theme[vitrineType].main} />
                <Text dangerouslySetInnerHTML={{ __html: this.renderOferece(anuncioById) }} />
                <Separator showSeparator={true} texto="Anúncios similares" vitrinecolor={Theme[vitrineType].main} />

                {/* Sugestão de anúncio */}
                <Container width="auto" direction="row" firstContainer={false}>

                  <ContainerSliderAnuncio>
                    <Slider className="slider" {...settingsCarrossel} >
                        {anunciosRelacionados &&
                          anunciosRelacionados.map(item => {
                            return <SuggestCard key={`suggest-card-${item.id}`} anuncio={item} authentication={authentication} />
                          })
                        }
                    </Slider>
                  </ContainerSliderAnuncio>

                </Container>

                <Separator showSeparator={true} texto="Viu algum problema?" vitrinecolor={Theme[vitrineType].main} />

                <ReportarAnuncio idAnuncio={anuncioById.id} listaMotivos={motivosList} analyticsJson={this.state.analyticsJson} />

                <Separator showSeparator={true} texto="Compartilhar nas redes sociais" vitrinecolor={Theme[vitrineType].main} />

                <Container direction="row" justify="space-around">
                  <FacebookShared href={`https://www.facebook.com/sharer.php?u=${process.env.BASE_URL + this.props.location.pathname}`} target='_blank' onClick={this.sendShareEvent} />
                  <TwitterShared href={`http://twitter.com/intent/tweet?text=${anuncioById.titulo};?>&url=${process.env.BASE_URL + this.props.location.pathname}`} target='_blank' onClick={this.sendShareEvent} />
                  <EmailShared href={`mailto:?body=${process.env.BASE_URL + this.props.location.pathname}`} onClick={this.sendShareEvent} />
                </Container>

              </AnuncioContainer>

              <Container 
                width="auto" 
                direction="unset" 
                align="unset" 
                justify="unset" 
                firstContainer={false} 
                noPadding={true}>
              
              <Contato
                anuncioId={anuncioById.id}
                hideForm={anuncioById.origem === "CRAWLER"}
                defaultMsg={`${process.env.BASE_URL}${this.props.location.pathname}`}
                anunciante={anuncioById.anunciante}
                analyticsJson={this.state.analyticsJson} />

              </Container>

            </Container>
          }

          {showLoading && <Loading />}

        </Container>

      </React.Fragment>

    );

  }

}

const mapStateToProps = store => ({
  anunciosRelacionados: store.anuncio.relacionados,
  anuncioById: store.search.anuncioById,
  motivosList: store.anuncio.motivosList,
  authentication: store.authentication.authentication
});

function loadData(store, match) {
  const anuncioId = anuncioMockup(match.params.id);

  return Promise.all([
    store.dispatch(fetchAnunciosBy(anuncioId)),
    store.dispatch(fetchAnunciosRelacionados({ idAnuncio: anuncioId, qtdAnuncios: 8 }))
  ]);
}

export default {
  loadData,
  component: connect(mapStateToProps, { fetchAnunciosBy, fetchMotivos, fetchAnunciosRelacionados })(Anuncio)
};
