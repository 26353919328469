import React, { Component } from 'react';

// Component
import { 
    LoadingAnimate,
    LoadingContainer,
    LoadingLogo,
    LoadingLabel
} from './loading-styled';

import LogoClassiAnimated from '../../resources/svg/logo-classi-animated.svg';

export default class Loading extends Component{
    render(){

        return(
            <LoadingContainer>
                <LoadingAnimate img={ LogoClassiAnimated } />
            </LoadingContainer>
        );

    }
}