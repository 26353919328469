import {
    FETCH_ANUNCIOS_LIST,
    FETCH_ANUNCIO_BY,
    CLICK_UPDATE_ANUNCIO,
    FETCH_CATEGORIES_LIST,
    FETCH_STATE_LIST,
    FETCH_CITY_LIST
} from '../actions/types';

const INITIAL_STATE = {
    searchFilterData: [],
    anuncioById: {},
    categoryList: [],
    stateList: [],
    cityList: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ANUNCIOS_LIST:
            return { ...state, searchFilterData: action.payload }

        case FETCH_ANUNCIO_BY:
            return { ...state, anuncioById: action.payload }
        
        case CLICK_UPDATE_ANUNCIO: 
            return { ...state, selectedCategoria: action.selectedCategoria };

        case FETCH_CATEGORIES_LIST:
            return { ...state, categoryList: action.payload}
            
        case FETCH_STATE_LIST:
            return { ...state, stateList: action.payload}

        case FETCH_CITY_LIST:
            return { ...state, cityList: action.payload}


        default:
            return state;
    }
}