import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCategoria, fetchAnunciosList } from  './../../actions';
import FilterSelect from '../filtro-select/FiltroSelect';
import { searchFilterList } from './../../resources/mockups/filtro-mockup';
import { StringUtils } from './../../resources/methods/StringUtils';

// Styles
import { 
    FilterContainer,
    FilterInput, 
    FilterLabel,
    Filter,
    FilterApllyBtn,
    FilterDualItemContainer,
} from './../commons/filter-styled';

// Colors
import { Theme } from './../commons/vitrine-colors';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();
class FiltroBusca extends Component{
    constructor(props){
        super(props);

        this.state = {
            vitrine: 'negocios',
            defaultTexto: this.props.defaultTexto,
            inputValue: '',
            categoryFilter: {},
            presetFiltro: {},
            query: '',
            vlrMinimo: '',
            vlrMaximo: ''
        }

        this.cidadeFilterRef = React.createRef();
        this.subcategoriaFilterRef = React.createRef();
    }
    

    handleInput = (e) => {
        let newState = { [e.target.name]: e.target.value };

        //Condição especial para o input do tipo palavra chave, garantido fim do preset de filtros no primeiro input manual.
        if (e.target.name === 'query' && this.state.presetFiltro.query) {
            newState['presetFiltro'] = { 'query' : '' };
        }

        this.setState(newState);
    };

    filterByBtn = () => {
        this.props.handleFilter('query', this.state.query);
    };

    filterArray = () => {
        const { vlrMaximo, vlrMinimo } = this.state;

        if( (vlrMaximo != '' && vlrMinimo != '') && (parseFloat(vlrMaximo) < parseFloat(vlrMinimo)) ){
            this.setState({ vlrMinimo: vlrMaximo, vlrMaximo: vlrMinimo });
            this.props.handleFilter(['vlrMinimo', 'vlrMaximo'], [vlrMaximo, vlrMinimo]);
        } else{
            this.props.handleFilter(['vlrMinimo', 'vlrMaximo'], [this.state.vlrMinimo, this.state.vlrMaximo]);
        }
    }

    // TODO: melhorar busca de filtro
    handleFilterPath = (key, value) => {
        this.props.handleFilter(key, value);

        if(key === "estado"){
            this.cidadeFilterRef.current.resetFilter();
        }

        if(key === "categoriaPai"){
            this.subcategoriaFilterRef.current.resetFilter();
        }
    };

    componentDidUpdate(prevProps) {
        const { searchFilterData, presetFiltro } = this.props;

        //TODO: Aviliar necessidade de deep check entre searchFilterData
        if (
            (prevProps.searchFilterData != searchFilterData) &&
            presetFiltro
        ) {
            let estado = null;
            let cidade = null;
            let categoria = null;
            let query = null;

            if (searchFilterData.estados && presetFiltro.estado) {
                let indexEstado = searchFilterData.estados.findIndex(function(o) {
                    return o.descricao.toLowerCase() === presetFiltro.estado;
                });
                estado = searchFilterData.estados[indexEstado];
            }
            if (searchFilterData.cidades && presetFiltro.cidade) {
                let indexCidade = searchFilterData.cidades.findIndex(function(o) {
                    return StringUtils.cleanString(o.descricao) === presetFiltro.cidade;
                });
                cidade = searchFilterData.cidades[indexCidade];
            }
            if (searchFilterData.categoriasPai && presetFiltro.categoria) {
                let indexCategoria = searchFilterData.categoriasPai.findIndex(function(o) {
                    return StringUtils.cleanString(o.descricao) === presetFiltro.categoria;
                });
                categoria = searchFilterData.categoriasPai[indexCategoria];
            }

            if (presetFiltro.query) {

                query = presetFiltro.query;
            }

            let presetFiltroValues = {};

            Object.assign(presetFiltroValues, estado ? {'estado': estado} : null);
            Object.assign(presetFiltroValues, cidade ? {'cidade': cidade} : null);
            Object.assign(presetFiltroValues, categoria ? {'categoria': categoria} : null);
            Object.assign(presetFiltroValues, query ? {'query': query} : null);

            this.setState({'presetFiltro': presetFiltroValues});
        }
    }

    keyPressed = (event) => {      
        if(event.key === "Enter"){
            if(event.target.name == 'query'){
                this.filterByBtn(searchFilterList.query.name)
            } else if(event.target.name == 'codigo'){
                this.filterByBtn(searchFilterList.code.name)
            }
        }
    }

    render(){
        const { searchFilterData } = this.props;
        let query = this.state.query || this.state.presetFiltro.query;
        if (!query) { query = '';}

        return(
            <FilterContainer direction="column" busca>

                {/* Palavra-chave */}
                <Filter direction="column" vitrinecolor={ Theme[vitrineType].main } busca>
                    <FilterLabel vitrinecolor={ Theme[vitrineType].main } busca>O que você procura?</FilterLabel>
                    <br />
                    <FilterInput 
                        name={searchFilterList.query.name}
                        placeholder={searchFilterList.query.placeholder} 
                        value={ query } 
                        key={searchFilterList.query.filterName}
                        vitrinecolor={ Theme[vitrineType].main } 
                        onChange={ this.handleInput }
                        onKeyPress={this.keyPressed}
                    />

                    <FilterApllyBtn vitrinecolor={ Theme[vitrineType].main } onClick={ this.filterByBtn }>Aplicar</FilterApllyBtn>
                </Filter>

                {/* CATEGORIA /  Subcategoria */}
                { searchFilterData.categoriasPai && searchFilterData.categoriasPai.length > 0 &&
                    <Filter direction="column" vitrinecolor={ Theme[vitrineType].main } busca>
                        <React.Fragment>
                            <FilterLabel 
                                vitrinecolor={ Theme[vitrineType].main } 
                                busca
                            >
                                { searchFilterList.categoriaPai.label }
                            </FilterLabel>
                            <FilterSelect 
                                name={ searchFilterList.categoriaPai.name }
                                placeholder={ searchFilterList.categoriaPai.placeholder }
                                options={ searchFilterData.categoriasPai }
                                filterName={ searchFilterList.categoriaPai.filterName}
                                handleFilterPath={ this.handleFilterPath }
                                defaultValue={ this.state.presetFiltro.categoria }
                            />
                        </React.Fragment>

                        <React.Fragment>
                            <FilterLabel 
                                vitrinecolor={ Theme[vitrineType].main } 
                                busca
                            >
                                Subcategoria
                            </FilterLabel>
                            <FilterSelect 
                                name={ searchFilterList.category.name }
                                placeholder={ searchFilterList.category.placeholder }
                                options={ searchFilterData.categorias ? searchFilterData.categorias : undefined }
                                filterName={ searchFilterList.category.filterName }
                                handleFilterPath={ this.handleFilterPath }
                                isDisabled={ searchFilterData.categorias ? false : true }
                                ref={this.subcategoriaFilterRef}
                            />
                        </React.Fragment>
                    </Filter>
                }

                {/* Estado/Cidade */}
                { searchFilterData.estados && searchFilterData.estados.length > 0 && 
                    <Filter direction="column" vitrinecolor={ Theme[vitrineType].main } busca>
                        {/* ESTADO */}
                        <React.Fragment>
                            <FilterLabel 
                                vitrinecolor={ Theme[vitrineType].main } 
                                busca
                            >
                                { searchFilterList.state.label }
                            </FilterLabel>
                            <FilterSelect 
                                name={ searchFilterList.state.name }
                                placeholder={ searchFilterList.state.placeholder }
                                options={ searchFilterData.estados }
                                filterName={ searchFilterList.state.filterName}
                                handleFilterPath={ this.handleFilterPath }
                                defaultValue={ this.state.presetFiltro.estado }
                            />
                        </React.Fragment>

                        {/* CIDADE */}
                        <React.Fragment>
                            <FilterLabel 
                                vitrinecolor={ Theme[vitrineType].main } 
                                busca
                            >
                                { searchFilterList.city.label }
                            </FilterLabel>
                            <FilterSelect 
                                name={ searchFilterList.city.name }
                                placeholder={ searchFilterList.city.placeholder }
                                options={ searchFilterData.cidades ? searchFilterData.cidades : undefined }
                                filterName={ searchFilterList.city.filterName}
                                handleFilterPath={ this.handleFilterPath }
                                isDisabled={ searchFilterData.cidades ? false : true }
                                defaultValue={ this.state.presetFiltro.cidade }
                                ref={this.cidadeFilterRef} 
                            />
                        </React.Fragment>
                    </Filter>
                }
                
                {(searchFilterData.formacoes && searchFilterData.formacoes.length > 0 || 
                searchFilterData.experiencias && searchFilterData.experiencias.length > 0) &&
                    <Filter direction="column" vitrinecolor={ Theme[vitrineType].main } busca>
                        {/* ESCOLARIDADE */}
                        { searchFilterData.formacoes && searchFilterData.formacoes.length > 0 &&
                            <React.Fragment>
                                <FilterLabel 
                                    vitrinecolor={ Theme[vitrineType].main } 
                                    busca
                                >
                                    { searchFilterList.scholarity.label }
                                </FilterLabel>
                                <FilterSelect 
                                    name={ searchFilterList.scholarity.name }
                                    placeholder={ searchFilterList.scholarity.placeholder }
                                    options={ searchFilterData.formacoes }
                                    handleFilterPath={ this.handleFilterPath }
                                    filterName={ searchFilterList.scholarity.filterName}
                                />
                            </React.Fragment>
                        }

                        {/* EXPERIÊNCIA */}
                        { searchFilterData.experiencias && searchFilterData.experiencias.length > 0 &&
                            <React.Fragment>
                                <FilterLabel 
                                    vitrinecolor={ Theme[vitrineType].main } 
                                    busca
                                >
                                    { searchFilterList.experience.label }
                                </FilterLabel>
                                <FilterSelect 
                                    name={ searchFilterList.experience.name }
                                    placeholder={ searchFilterList.experience.placeholder }
                                    options={ searchFilterData.experiencias }
                                    handleFilterPath={ this.handleFilterPath }
                                    filterName={ searchFilterList.experience.filterName}
                                />
                            </React.Fragment>
                        }
                    </Filter>
                }

                <Filter direction="column" vitrinecolor={ Theme[vitrineType].main } busca>
                    <FilterLabel vitrinecolor={ Theme[vitrineType].main } busca>Qual a faixa de preço procura?</FilterLabel>

                    <FilterDualItemContainer>
                        <FilterInput 
                            placeholder={ searchFilterList.vlrMinimo.placeholder } 
                            value={ this.state.vlrMinimo } 
                            name={ searchFilterList.vlrMinimo.filterName }
                            vitrinecolor={ Theme[vitrineType].main } 
                            onChange={ this.handleInput }
                            busca
                        />
                        <span>a</span>
                        <FilterInput 
                            placeholder={ searchFilterList.vlrMaximo.placeholder } 
                            value={ this.state.vlrMaximo }
                            name={ searchFilterList.vlrMaximo.filterName }
                            vitrinecolor={ Theme[vitrineType].main }
                            onChange={ this.handleInput }
                            onKeyPress={this.keyPressed}
                            busca
                        />
                    </FilterDualItemContainer>

                    <FilterApllyBtn vitrinecolor={ Theme[vitrineType].main } onClick={ this.filterArray }>Aplicar</FilterApllyBtn>

                </Filter>

            </FilterContainer>
        );
    }
}

const mapStateToProps = store => ({
    selectedCategoria: store.selectCategoria.selectedCategoria,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ selectCategoria }, dispatch);

export default connect(mapStateToProps, { fetchAnunciosList })(FiltroBusca);