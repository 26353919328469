import { StringUtils } from './StringUtils';

/**
 * Suporte as URL's e suas variações de acordo com as as regras do Classi
 */
export const UrlsClassiUtils = {
  URL_ESTADO_REGEX: /\/\w{2}\-|\/\w{2}$/, //Match para "(...)/\w\w-(...)" ou "/\w\w$" que é a condição de UF na URL do Classi.
  URL_CIDADE_REGEX: /\/\w{2}\-[\w+\+]+\/{0,1}/, //Match para "(...)/{{UF}}-\w+[/(...)|$]" que é a condição de Cidade na URL do Classi.
  URL_CATEGORIA_REGEX: /\/busca\/\w{2}\-\w+\/([\w+\+]*)|\/busca\/[\w+\+]+/, //Match para o primeiro grupo de string após /busca/[MATCH] OR /busca/{uf}-{cidade}/[MATCH], que é a condição para Categoria na URL.
  SEARCH_QUERY_REGEX: /query=.*&|query=.*$/,
  SEARCH_PAGINA_REGEX: /pagina=\d+/,

  TIPOS_DE_BUSCA: {},

  getEstado: function (url) {
    let estado = StringUtils.getPrimeiroMatch(url, this.URL_ESTADO_REGEX);

    return estado ? estado.replace(/\/|-/g, '') : estado;
  },
  getCidade: function (url) {
    let cidade = StringUtils.getPrimeiroMatch(url, this.URL_CIDADE_REGEX);

    if (cidade) {
      cidade = cidade.replace(/^\/\w{2}-/, '');
      cidade = cidade.replace('/', '');
    }

    return cidade;
  },
  getCategoria: function (url) {
    let match = StringUtils.getMatch(url, this.URL_CATEGORIA_REGEX);

    //Javascript retorna o tamanho do array igual a regex + Num. de Grupos.
    if (match && match.length > 1) {
      let regexString = match[0];
      let regexGroupString = match[1];

      let categoria = regexGroupString ? regexGroupString : regexString

      return categoria.replace('/busca/', '');
    }

    return null;
  },
  getQuery: function (search) {
    let query = StringUtils.getPrimeiroMatch(search, this.SEARCH_QUERY_REGEX)

    return query ? query.replace('query=', '') : query;
  },
  getPagina: function (search) {
    let pagina = StringUtils.getPrimeiroMatch(search, this.SEARCH_PAGINA_REGEX);

    return pagina ? pagina.replace('pagina=', '') : pagina;
  },
  /**
   * Recebe o pathname, mais a UF, Cidade e a Categoria, retornando o nome pathname com os dados.
   * Lembrando que categoria somente é inserida no pathname se existir uf e cidade, e cidade só 
   * e inserida se existir uf.
   * 
   * Exemplo:
   *  pathname: busca/vagas/uf-go/domestica
   *  uf: DF
   *  cidade: Brasília
   *  categoria: Doméstica
   * 
   *  retorno: 'busca/vagas/df-brasilia/domestica'
   * 
   * @param {String} pathname
   * @param {String} uf
   * @param {String} cidade
   * @param {String} categoria
   */
  makeNewPathname: function (pathname, uf, cidade, categoria) {
    let newPathname = '';

    if (pathname) {
      let pathnameBase = pathname.match(/\w+(?=\/)|\w+$/);

      if (pathnameBase && pathnameBase.length) {
        pathnameBase = pathnameBase[0];

        newPathname = pathnameBase + this.constructFiltrosPathname(uf, cidade, categoria);
      }
    }

    return newPathname;
  },
  /**
   * Cria a string search, a partir dos parametros de query e pagina passado como valores.
   * 
   * Exemplo:
   *  searchString: 'pagina=1&query=cola'
   *  query: 'Ferro de Passar'
   *  pagina: '2'
   * 
   *  retorno 'pagina=2&query=Ferro de Passar'
   * 
   * @param {String} searchString
   * @param {String} query
   * @param {String} pagina
   * @param {Boolean} stayInPage Caso verdadeiro utiliza a página (se existir) da searchString, caso contrário utiliza o valor de 'pagina' e em último caso '1'.
   */
  makeNewSearch: function (searchString, query, pagina, stayInPage) {
    let search = '';

    if (searchString) {
      let queryString = '';
      if (query) {
        queryString = 'query=' + query;
      }

      let page = 'pagina=' + (pagina || pagina === 0 ? pagina : '1');
      if (stayInPage) {
        let pagePos = this.getPagina(searchString);

        if (pagePos) {
          page = 'pagina=' + pagePos;
        }
      }

      search = page + (queryString ? '&' + queryString : '');
    }

    return search;
  },
  /**
   * Constrói os filtro para pathname com base nos parâmetros passados.
   * 
   * Exemplo:
   *  uf: DF
   *  cidade: Brasília
   *  categoria: undefined
   * 
   *  retorno 'df-brasilia/'
   * 
   * @param {String} uf 
   * @param {String} cidade 
   * @param {String} categoria 
   */
  constructFiltrosPathname: function (uf, cidade, categoria) {
    let filtroPathname = '';

    if (uf && cidade && categoria) {
      filtroPathname = '/' + StringUtils.cleanString(uf).toLowerCase();
      filtroPathname += '-' + StringUtils.cleanString(cidade) + '/';
      filtroPathname += StringUtils.cleanString(categoria);
    } else if (categoria) {
      filtroPathname = '/' + StringUtils.cleanString(categoria);
    }

    return filtroPathname;
  }
};