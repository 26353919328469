import React from "react";
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Theme } from '../commons/vitrine-colors';

import defaultImg from './../../resources/svg/logos/logo-classi-negocios.svg';
import bgImage from './../../resources/svg/bg-vitrines/fundo-negocios.png';

export const CardSuggestPaper = styled(Paper)`
    &&{
        width: 175px;
        height: 300px;

        border-radius: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        overflow: hidden;
        position: relative;

        margin: 10px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 768px){
            width: 150px;
        }
    }
`;

export const PreviewImg = styled(props => (
    <Link href={props.url} {...props}>
        <img src={props.src} />
    </Link>
))`
    &&{
        width: 100%;
        height: 50%;

        @media screen and (max-width: 768px){
            height: 55%;
        }
    }

    img {
        width: 100%;
        height: 100%;

        background: url(${ defaultImg}), url(${bgImage});
        background-color: ${Theme['negocios'].main};
        background-position: 50%, 57%;
        background-repeat: no-repeat;
        background-size: 90%, auto 100%;

        object-fit: cover;
    }
`;

export const CardSuggestTitulo = styled(Link)`
    &&{
        font-size: 18px;

        color: #000;
    
        padding: 0 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
`;

export const CardSuggestValor = styled.p`
    font-size: 18px;
    font-weight: bold;
    
    color: ${Theme['negocios'].main};

    padding: 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const CardSuggestSubtitulo = styled(Link)`
    &&{
        color: #000;

        padding: 0 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
`;